import React from "react";
import background from './background-sky.jpg';
import headerSVG from './logo-e-recup.svg';

const Erecup = () => {
  const nousContacter = () => {
    window.open("https://www.gestonline.com", "_blank");
  };
  
  const espaceClient = () => {
    window.open("https://app.e-recup.com/", "_blank");
  };
  

  return (
    <div style={{
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      height: "100vh",
      width: "100vw",
      padding: "20px",
    }}>
      <img
        src={headerSVG}
        alt="Header Logo"
        style={{
          alignItems: "center",
          height: "40px",
          marginBottom: "30px",
        }}
      />
      <h1 style={{
        color: "black",
        fontSize: "59px",
        textAlign: "center",
        marginBottom: "20px",
        whiteSpace: "normal",
      }}>
        Echangez <span style={{ color: "white", padding: "0 4px" }}>facilement</span> vos documents et fichiers
      </h1>
      <p style={{
        color: "black",
        fontSize: "20px",
        textAlign: "center",
        marginBottom: "30px",
        maxWidth: "600px",
        whiteSpace: "normal",
      }}>
        Invitez vos contacts et récupérez tous vos documents administratifs, au sein d’un espace de partage intelligent.
      </p>
      <div style={{ display: "flex", gap: "20px", marginBottom: "30px" }}>
        <button
          onClick={espaceClient}
          style={{
            backgroundColor: "#fff",
            color: "#0F8BA6",
            padding: "12px 24px",
            borderRadius: "8px",
            border: "none",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            width: "200px",
          }}
        >
          Espace client
        </button>
        <button
          onClick={nousContacter}
          style={{
            backgroundColor: "transparent",
            color: "#fff",
            padding: "12px 24px",
            borderRadius: "8px",
            border: "2px solid #fff",
            cursor: "pointer",
            fontSize: "16px",
            fontWeight: "bold",
            width: "200px",
          }}
        >
          Nous contacter
        </button>
      </div>
    </div>
  );
};

export default Erecup;
